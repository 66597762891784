import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function AnnouncementPage() {
  return (
    <Layout>
      <SEO
        title="Postponing Our Wedding"
        keywords={[`Postponing our wedding due to covid-19`]}
      />
      <div class="max-w-full">
        <div class="relative">
          <div class="relative lg:flex overflow-hidden">
            <div id="accomodations" class="relative lg:w-6/12 bg-white h-full">
              <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                <p class="mb-6 text-2xl">
                  Postponement Announcement
                </p>
                <p class="text-gray-900">
                  Dear Friends and Family,
                  <br></br>
                </p>
                <br></br>
                <p>
                  Due to ongoing heath concerns, we have decided to postpone our wedding until <b>Augst 28, 2021</b>. Everything about our special day will remain the same except for the date. While we are extremely saddened about the postponement, this was an easy decision as the health and safety of our loved ones is paramount. We are working with our vendors to take additional precautions as we adjust to our new normal.
                </p>
                <br></br>
                <p>
                  We will be sending "Change the Date" announcements to all guests in the near future!  
                </p>
                <br></br>
                <p>
                  We are so grateful to our friends and family for your ongoing love and support. We can't wait to celebrate with you all at a later date. 
                </p>
                <br></br>
                <p>
                  If you have any questions, please contact our wedding planner <a href="mailto: sarah@socialblissevents.com" class="text-underline text-blue-600">Sarah Patton</a>.
                </p>
                <br></br>
                <p>
                  There'll be light after dark, someday when we aren't six feet apart.
                </p>
                <br></br>
                <p>
                  Love, 
                </p>
                <br></br>
                <p>
                  Justin and Betsy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AnnouncementPage;
